import AuthUtils from '../AuthUtils';
import ApiUtils from './index';

const baseConfig = () => ({
  headers: {
    'content-type': 'application/json',
    Authorization: `Bearer ${AuthUtils.getToken()}`,
  },
  baseURL: process.env.REACT_APP_API,
});

const HasuraApi = {
  get(path, config = {}) {
    return ApiUtils.get(path, {
      ...baseConfig(),
      ...config,
    });
  },
  post(path, payload, config = {}) {
    return ApiUtils.post(path, payload, {
      ...baseConfig(),
      ...config,
    });
  }
};

export default HasuraApi;
